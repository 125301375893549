<template>
  <div class="home">
    <div class="banner">
       <div class="banner-bg" ref="bannerBg"></div>
       <div class="margin-center  flex-direction-row">
         <div class="banner-left" ref="banner-left">
           <p class="banner-p1">焕启商业增长</p>
           <p class="banner-p2">以用户体验为本，全面拥抱终端能力<br>为客户带来创新商业价值</p>
           <p class="banner-p3">YOUR SMARTER LIFE STARTS HERE</p>
         </div>
        <video class="video" preload autoplay="autoplay" muted="muted" loop="loop" src="@/assets/image/banner.mp4" ></video>
      </div> 
    </div>
    <div class="introduce margin-center flex-direction-row flex-between-center">
       <div class="introduce-left flex-direction-col" ref="introduce-left">
         <el-image
          class="el-image-img"
         
          :src="require('@/assets/image/01.png')"
          fit="contain"></el-image>
           <el-image
           class="el-image-img"
       
          :src="require('@/assets/image/02.png')"
          fit="contain"></el-image>
       </div>
       <div class="introduce-right flex-start flex-direction-col" ref="introduce-right">
           <div class="introduce-right-text">
            悦木互动是专注于移动终端的整合营销公司，致力于为客户制定移动端全方位整合及营销解决方案，全方位满足客户在移动端各个广告领域的推广需求，真正实现品效合一的投放效果。公司拥有优质的媒体资源、完美的媒介组合、良好的媒体关系、畅通发达的渠道和广阔的人脉关系，为不同互联网企业提供整合营销、广告创意、媒体代理等全方位的传播推广服务。
           </div>
           
       </div>
    </div>
    <div class="circle">
         <div class="circle-line"></div>
         <div class="circle-bg scroll-magic-transition" ref="circle-bg"></div>
         <div  ref="front-bg"
          class="scroll-magic-transition front-bg"></div>
        
          <el-image
          class="front-img"
          style="width: 80px; height: 80px;"
          :src="require('@/assets/image/05.png')"
          fit="contain"></el-image>
          <div class="fade-up margin-center flex-direction-row">
              <div class="circle-group">
                 <div class="circle-group-top flex-direction-row">
                     <div class="circle-group-top-circle"></div>
                     <div class="circle-group-top-text">
                       <p>SMART BUSINESS</p> 
                       <p>PRODUCT LAYOUT</p>
                     </div>
                 </div>
                 <div class="circle-group-bottom">
                     智慧商业产品布局
                 </div>  
              </div>
              <div class="circle-group">
                 <div class="circle-group-top flex-direction-row">
                     <div class="circle-group-top-circle circle-group-top-circle-color"></div>
                     <div class="circle-group-top-text circle-group-top-circle-txtcolor">
                       <p>COMPREHENSIVE</p> 
                       <p>SERVICE SYSTEM</p>
                     </div>
                 </div>
                 <div class="circle-group-bottom">
                     全方位服务体系
                 </div>  
              </div>
              <div class="circle-group">
                 <div class="circle-group-top flex-direction-row">
                     <div class="circle-group-top-circle"></div>
                     <div class="circle-group-top-text">
                       <p>MULTIVARIATE</p>
                       <p>RESOURCE MATRIX</p>
                     </div>
                 </div>
                 <div class="circle-group-bottom">
                     多元资源矩阵
                 </div>  
              </div>
              <div class="circle-group">
                 <div class="circle-group-top flex-direction-row">
                     <div class="circle-group-top-circle circle-group-top-circle-color"></div>
                     <div class="circle-group-top-text circle-group-top-circle-txtcolor">
                       <p>TECHNOLOGICAL</p>
                       <p>INNOVATION</p>
                     </div>
                 </div>
                 <div class="circle-group-bottom">
                     科技创新
                 </div>  
              </div>
          </div>
    </div>
    <div class="card" ref="card">
      <div class="card-box" ref="card-box">
         <div class="card-list margin-center scroll-magic-transition" ref="card1">
              <div class="big-img">
                  <img src="../assets/image/07.jpg" alt="">
              </div>
              <div class="card-text">
                  <div class="text-p">
                     <p>产品及服务</p>
                     <p>PRODUCTS & SERVICES</p>
                  </div>
                  <div class="text-h1">
                     <h1>信息流广告</h1>
                     <p>主要依托OPPO浏览器以及新闻资讯进行服务，<br>为您带来流量和用户</p>
                  </div>
                  <div class="text-btn scroll-magic-transition" @click="linkss('/product/advertisement-info')">
                     查看更多 <i class="el-icon-right animate scroll-magic-transition1"></i>
                  </div>
              </div>
              <div class="row-img">
                  <img src="../assets/image/06.png" alt="">
              </div>
         </div>
         <div class="card-list margin-center scroll-magic-transition" ref="card2">
              <div class="big-img">
                  <img src="../assets/image/08.jpg" alt="">
              </div>
              <div class="card-text">
                  <div class="text-p">
                     <p>产品及服务</p>
                     <p>PRODUCTS & SERVICES</p>
                  </div>
                  <div class="text-h1">
                     <h1>数字生活</h1>
                     <p>数字科技服务，<br>让美好生活触手可及</p>
                  </div>
                  <div class="text-btn scroll-magic-transition" @click="linkss('/product/advertisement')">
                     查看更多 <i class="el-icon-right animate scroll-magic-transition1"></i>
                  </div>
              </div>
              <div class="row-img">
                  <img src="../assets/image/06.png" alt="">
              </div>
         </div>
         <div class="card-list margin-center scroll-magic-transition" ref="card3">
              <div class="big-img">
                  <img src="../assets/image/09.jpg" alt="">
              </div>
              <div class="card-text">
                  <div class="text-p">
                     <p>产品及服务</p>
                     <p>PRODUCTS & SERVICES</p>
                  </div>
                  <div class="text-h1">
                     <h1>智能短信</h1>
                     <p>智能AIM短信为传统短信赋予了新的生命力，全新的展现<br>形式，更加智能化的场景，帮助客户实现智能连接人与服务的目标</p>
                  </div>
                  <div class="text-btn scroll-magic-transition" @click="linkss('/product/SMS')">
                     查看更多 <i class="el-icon-right animate scroll-magic-transition1"></i>
                  </div>
              </div>
              <div class="row-img">
                  <img src="../assets/image/06.png" alt="">
              </div>
         </div>
      </div>
    </div>
    <div class="news margin-center">
       <div class="title flex-between">
         <div>
            <p>新闻动态</p>
            <p>N E W S</p>
         </div>
         <div class="text-btn scroll-magic-transition" style="background: #f9f9f9; color: #1f252e">
            查看更多 <i class="el-icon-right animate scroll-magic-transition1"></i>
        </div>
       </div>
       <div class="new-card flex-between">
          <div class="new-card-list" @click="links(7)">
              <div class="warp">
                 <h1>《2022 OPPO营销经营通案》正式发布：高效增长，长效运营</h1>
                 <p>互联网用户规模增长趋向瓶颈，企业正在升级经营思路，转向对存量市场的价值挖掘。曾经粗放的买量模式已经不能适应当下复杂的商业场景，企业也在思考如何能够做好存量用户经营，探索生意增量，寻找长效增长之道。在这样的背景下，《2022 OPPO营销经营通案》正式发布，通过全域、精准、稳定、原生且高效的终端媒体底层价值与商业产品能力，助力企业将核心服务与用户群体连接，达成生意增长目标。</p>
              </div>
          </div>
          <div class="new-card-list" @click="links(8)">
              <div class="warp">
                 <h1>OPPO营销荣膺IAI年度影响力媒体，携手服务商斩获6项案例大奖</h1>
                 <p>携程旅行 x OPPO营销</p>
              </div>
          </div>
          <div class="new-card-list" @click="links(9)">
              <div class="warp">
                 <h1>智能AIM短信为传统短信赋予了新的生命力，全新的展现形式，更加智能化的场景</h1>
                 <p>帮助客户实现智能连接人与服务的目标。对企业进行品牌认证，增加用户信任感，提升短信打开率，短信内容卡片化，可有效增强用户体验，提升阅读率，设置场景按钮和公众号菜单，直达业务办理页面，提升营销转化率</p>
              </div>
          </div>
       </div>
    </div>
    <div class="swiper">
       <div class="swiper-bg scroll-magic-transition" ref="swiper-bg">
          <img src="../assets/image/12.png" alt="">
       </div>
       <div class="swiper-circle scroll-magic-transition" ref="swiper-circle">
           <img src="../assets/image/13.png" alt="">
       </div>
       <div class="swiper-card margin-center">
           <div class="title flex-between">
            <div>
                <p>荣誉资质</p>
                <p>H O N O R S</p>
            </div>
          </div>
          <div class="swiper-card-list">
              <el-carousel trigger="click" height="436px" :autoplay="false" type="card">
                <el-carousel-item v-for="(item, index) in honorsList" :key="index">
                  <img :src="item.url" alt="" style="height: 400px;border-radius: 2px;">
                  <h3 class="small">{{ item.title }}</h3>
                </el-carousel-item>
              </el-carousel>
          </div>
       </div>
       <div class="swiper-card margin-center">
           <div class="title flex-between">
            <div>
                <p>合作伙伴</p>
                <p>P A R T N E R S</p>
            </div>
          </div>
          <div class="swiper-card-list">
              <el-carousel trigger="click" height="442px" :autoplay="false">
                <el-carousel-item style="display: flex;flex-wrap: wrap;" class="img-list-card" v-for="(item, index) in partnersList" :key="index">
                  <div class="img-list " v-for="(items, indexs) in item.list" :key="indexs">
                      <img :src="items.url" alt="">
                  </div>
                </el-carousel-item>
              </el-carousel>
          </div>
       </div>
    </div>
  </div>
</template>

<script>

// @ is an alias to /src
// import HelloWorld from '@/components/head.vue'
import BrowserType  from "@/utils/utils";
export default {
  name: 'HomeView',
  components: {
    // HelloWorld
  },
  created() {
  
  },
  data() {
    return {
      browser: BrowserType() ? BrowserType() : 'Chrome',
      intNum: 0,
        honorsList:
        [
          {
            url:'/static/img/14.png',
            title: ''
          },
           {
            url:'/static/img/15.png',
            title: ''
          },
           {
            url:'/static/img/16.png',
            title: ''
          },
           {
            url:'/static/img/17.png',
            title: ''
          },
           {
            url:'/static/img/18.png',
            title: ''
          }
        ],
        partnersList: [
          {
            list: [
              {url:'/static/img/19.jfif'},
              {url:'/static/img/20.jfif'},
              {url:'/static/img/21.jfif'},
              {url:'/static/img/22.jfif'},
              {url:'/static/img/23.jfif'},
              {url:'/static/img/24.jfif'},
              {url:'/static/img/25.jfif'},
              {url:'/static/img/26.jfif'},
              {url:'/static/img/27.jfif'},
              {url:'/static/img/28.jfif'},
              {url:'/static/img/29.jfif'},
              {url:'/static/img/30.jfif'},
            ]
          },
          {
           list: [
              {url:'/static/img/31.jfif'},
              {url:'/static/img/32.jfif'},
              {url:'/static/img/33.jfif'},
              {url:'/static/img/34.jfif'},
              {url:'/static/img/35.jfif'},
              {url:'/static/img/36.jfif'},
              {url:'/static/img/37.jfif'},
              {url:'/static/img/38.jfif'},
              {url:'/static/img/39.jfif'},
              {url:'/static/img/40.jfif'},
              {url:'/static/img/41.jfif'},
              {url:'/static/img/42.jfif'},
            ]
          }
        ]
    }
  },
  mounted () {
     let self = this;
     setTimeout(function(){
       self.$refs['banner-left'].style='margin-top: 0px'
     }, 1000)
    //  console.log(new ScrollMagic.Controller())
    window.addEventListener('scroll', this.handleScroll) // 监听页面滚动
    console.log(this.browser);
  },
  methods: {
  // 获取页面滚动距离
  isMobile() {
    let flag = window.navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
    return !!flag
  },
    handleScroll () {
        let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        console.log(scrollTop, '滚动距离')
        if (scrollTop > 1) {
          this.$refs['bannerBg'].style='bottom: -500px'
        } else {
           this.$refs['bannerBg'].style='bottom: 1px'
        }
        if (scrollTop > 236) {
           this.$refs['introduce-left'].style= 'margin-top: 100px'
           this.$refs['introduce-right'].style= 'margin-top: 100px'
        }
        if (scrollTop > 1200) {
          this.$refs['circle-bg'].style= 'bottom: 60px'
          this.$refs['front-bg'].style= 'top: -380px'
        } else {
          this.$refs['circle-bg'].style= 'bottom: -160px'
          this.$refs['front-bg'].style= 'top: -100px'
        }
        if (this.isMobile()) {
             this.$refs['card-box'].style='position: relative; height: 2200px'
              this.$refs['card'].style='padding-bottom: 100px; padding-top: 100px'
             this.$refs['card1'].style='display: flex;height: 700px;margin: 20px 0'
              this.$refs['card2'].style='display: flex;height: 700px;margin: 20px 0'
              this.$refs['card3'].style='display: flex;height: 700px;margin: 20px 0'
        } else {
        if (this.browser == 'Chrome') {
         
           if (scrollTop >= 1909 && scrollTop < 2500) {
          // debugger
              this.$refs['card-box'].style='position: fixed'
              this.$refs['card'].style='padding-bottom: 937px; padding-top: 0px'
              this.$refs['card1'].style='display: flex'
              this.$refs['card2'].style='display: none'
              this.$refs['card3'].style='display: none'
            } else if (scrollTop >= 2500 && scrollTop < 2800){
              this.$refs['card1'].style='display: none'
              this.$refs['card2'].style='display: flex'
              this.$refs['card3'].style='display: none'
              this.$refs['card-box'].style='position: fixed'
            } else if (scrollTop >= 2800 && scrollTop < 4000){
              this.$refs['card1'].style='display: none'
              this.$refs['card2'].style='display: none'
              this.$refs['card3'].style='display: flex'
                this.$refs['card-box'].style='position: relative'
              this.$refs['card'].style='padding-bottom: 0px; padding-top: 937px'
            } else {
              this.$refs['card-box'].style='position: relative'
            }
        } else {
        
            if (scrollTop >= 1766 && scrollTop < 2300) {
              this.$refs['card-box'].style= `position: fixed; top:${scrollTop%2 === 0 ? 0 : 1}px`
              this.$refs['card'].style='padding-bottom: 937px; padding-top: 0px'
              this.$refs['card1'].style='display: flex'
              this.$refs['card2'].style='display: none'
              this.$refs['card3'].style='display: none'
            } else if (scrollTop >= 2300 && scrollTop < 2600){
              this.$refs['card1'].style='display: none'
              this.$refs['card2'].style='display: flex'
              this.$refs['card3'].style='display: none'
              this.$refs['card-box'].style=`position: fixed; top:${scrollTop%2 === 0 ? 0 : 1}px`
            } else if (scrollTop >= 2600 && scrollTop < 3700){
              this.$refs['card1'].style='display: none'
              this.$refs['card2'].style='display: none'
              this.$refs['card3'].style='display: flex'
              this.$refs['card-box'].style='position: relative'
              this.$refs['card'].style='padding-bottom: 0px; padding-top: 937px'
            } else {
              this.$refs['card-box'].style='position: relative'
            }
           
          
        }
        }
        
        if (scrollTop >= 4500) {
          this.$refs['swiper-circle'].style='bottom: 900px'
           this.$refs['swiper-bg'].style='bottom: 0px'    
         
        } else {
          this.$refs['swiper-circle'].style='bottom: 500px'  
           this.$refs['swiper-bg'].style='bottom: -100px' 
        }
    },
    links(id) {
           this.$router.push({
               path: '/newsDetils',
               query: {
                   id: id
               }
           })
    },
    linkss(path) {
           this.$router.push({
               path: path
           })
    }
	},
	// 滚动重置
    beforeDestroy () {
        window.removeEventListener('scroll', this.handleScroll)
    },
}
</script>
<style lang="scss">
$myColor: #4e9e80;
  // .home{
  //   margin-top: 48px;
  // }
  .video {
    width: 675px;
  }
  .el-image-img {
    width: 350px; height: 200px; border-bottom: 2px solid #e3e3e3; padding: 10px 0
  }
  .banner {
    background: #f9f9f9;
    position: relative;
    display: flex;
    flex-direction:row;
    width: 100%;
    height: calc(100vh - 48px);
    overflow: hidden;
  }
  .banner-bg{
    background: url('../assets/image/banner-left.png') no-repeat;
    transition: all 2s cubic-bezier(0,0,.2,1);
    background-size: cover;
    position: absolute;
    width: 550px;
    height: 550px;
    bottom: 1px;
    transform: scale(10, 10, 10);
  }
  .banner-left {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    transition: all 1.2s cubic-bezier(0,0,.2,1);
    margin-top: -150px;
     background: #f9f9f9;
     p{
        text-align: left;
        margin: 10px 0;
        white-space:nowrap;
     }
  }
  .banner-right {
    flex: 1;
    width: 50%;
  }
  .banner-p1{
    font-size: 72px;
    font-weight: 900;
  }
  .banner-p2{
    font-size: 37px;
  }
  .banner-p3{
    font-size: 20px;
    font-weight: 100;
  }
  .introduce-left{
    margin: 300px 0 100px 0;
    transition: all 1.2s cubic-bezier(0,0,.2,1);
  }
  .introduce-right{
     margin: 300px 0 100px 100px;
     transition: all 1.2s cubic-bezier(0,0,.2,1);
  }
  .introduce-right-text{
    font-size: 24px;
    line-height: 60px;
    text-align: left;
  }
  .circle{
    background: #eee;
    position: relative;
    overflow: hidden;
    .circle-line {
      position: absolute;
      z-index: 0;
      width: 1200px;
      height: 1200px;
      top: 143px;
      left: 26%;
      border: 2px solid #fff;
      border-radius: 50%;
    }
    .circle-bg{
      width: 1000px;
      height: 1000px;
      position: absolute;
      bottom: -160px;
      left: -40%;
      background: #f8f8f8;
      border-radius: 50%;
    }
    .front-bg{
      position: absolute;
      z-index: 0;
      right: -670px;
      top: -100px;
      width: 1200px;
      height: 1000px;
      background: url('../assets/image/04.png') no-repeat;
    }
    .fade-up{
      height: 320px;
      padding-top: 117px;
      justify-content: space-between;
      position: relative;
      z-index: 100;
    }
    .front-img {
      position: absolute;
      top: 142px;
      left: 6%;
    }
    .circle-group-top-circle{
      margin-right: 11px;
      width: 32px;
      height: 32px;
      border: 4px solid #000;
      border-radius: 50%;
    }
    .circle-group-top{
      align-items: center;
    }
    .circle-group-top-circle-color{
      border: 4px solid $myColor;
    }
    .circle-group-top-circle-txtcolor{
     color: $myColor;
    }
    .circle-group-top-text{
      p{
        white-space: nowrap;
        text-align: left;
        font-size: 12px;
        margin: 3px 0;
        letter-spacing: 3px;
      }
      
    }
    .circle-group-bottom {
        font-size: 40px;
        font-weight: bold;
        margin-top: 10px;
    }
   
  }
   .card {
      inset: auto;
      margin: 0px;
      display: block;
      position: relative;
      box-sizing: content-box;
      min-height: 800px;
      height: auto;
      padding-top: 0px;
      padding-bottom: 937px;
      .card-box {
        position: relative;
        margin: auto;
        inset: 0px auto auto 0px;
        box-sizing: border-box;
        width: 100%;
        height: 100vh;
        overflow: hidden;
        background: #f9f9f9;
        .card-list {
          height: 100vh;
          display: flex;
          align-items: center;
           .big-img {
             width: 648px;
             height: 680px;
             img {
               border-radius: 12px;
               width: 100%;
               height: 100%;
             }
           }
           .card-text {
             height: 680px;
             display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-left: 164px;
           }
           .row-img {
              position: absolute;
              width: auto;
              top: 44%;
              left: 50%;
              transform: translate(-50%,-50%);
              height: 240px;
              img {
                height: 100%;
                width: auto;
              }
           }
           .text-p {
             
             p:first-child {
                font-size: 52px;
             }
             p:last-child {
                font-size: 16px;
                line-height: 18px;
                margin-top: 16px;
                letter-spacing: 6px
             }
           }
           .text-h1 {
             margin-top: 210px;
             h1 {
               font-size: 40px;
               line-height: 44px;
               text-align: left;
             }
             p {
              
               margin:32px 0 24px;
                  font-size: 18px;
                  line-height: 34px;
                  color: #41474e;
              
             }
           }
           
        }
      }
    }
  .text-btn {
      position: relative;
      overflow: hidden;
      width: 120px;
      height: 35px;
      line-height: 35px;
      text-align: center;
      border-radius: 35px;
      color: #f9f9f9;
      background: #3f3f3f;
      font-size: 0;
      font-size: 11px;
      cursor: pointer;
      i {
        position: relative;
        right: 0;
      }
    }
    .text-btn:hover {
        background: $myColor !important;
        .animate {
          right: -100px;
        }
    } 
  .news{
     padding-top: 120px;
  }
  .title {
    p:first-child {
      font-size: 52px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    p:last-child {
      margin-top: 16px;
    font-size: 16px;
    line-height: 18px;
    }
  }
  .new-card{ 
      margin: 70px 0 0px 0;
      height: 500px;
      .new-card-list {
          position: relative;
          width: 408px;
          height: 300px;
          cursor: pointer;
          border-radius: 12px;
          color: #fff;
          transition: all 2s cubic-bezier(0,0,.2,1);
          .warp {
            position: absolute;
            box-sizing: border-box;
            top: 60px;
            width: 100%;
            padding: 0 28px;
            h1{
              font-size: 20px;
              line-height: 36px;
              display: -webkit-box;
              overflow: hidden;
               font-weight: 700;
              -webkit-box-orient: vertical;
              text-align: left;
              -webkit-line-clamp: 2;
            }
            p {
              margin-top: 14px;
              font-size: 14px;
              text-align: left;
              line-height: 24px;
              word-break: break-word;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; /* 这里是超出几行省略 */
              overflow: hidden;
            }
          }
      }
      .new-card-list::before {
        content: "";
        position: absolute;
        width: 60px;
        height: 2px;
        background: #fff;
        top: 60px;
        left: 32px;
      }
      .new-card-list:nth-of-type(1) {
         background: url('../assets/image/09.png') no-repeat;
         &:hover {
         background: url('../assets/image/09.png') no-repeat -40px -40px;
        }
      }
      .new-card-list:nth-of-type(2) {
         background: url('../assets/image/10.png') no-repeat;
          &:hover {
         background: url('../assets/image/10.png') no-repeat -40px -40px;
        }
      }
      .new-card-list:nth-of-type(3) {
         background: url('../assets/image/11.png') no-repeat;
          &:hover {
         background: url('../assets/image/11.png') no-repeat -40px -40px;
        }
      }
    }
    .swiper {
      position: relative;
      background-color: #f9f9f9;
      box-sizing: border-box;
      padding-bottom: 100px;
      overflow: hidden;
      margin: 0 0 100px 0;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 382px;
        left: 0;
        background: #fff;
      }
      .swiper-bg {
        position: absolute;
        bottom: -100px;
        left: 0;
        width: 870px;
        height: 1217px;
        text-align: left;
        z-index: 0;
      }
     
          .swiper-circle{
            position: absolute;
            bottom: 500px;
            left: 90px;
            transition: all .5s cubic-bezier(0,0,.2,1);
            width: 108px;
            height: 108px;
              z-index: 1;
            img {
              width: 100%;
              height: 100%;
            }

          }
      .swiper-card {
        position: relative;
        z-index: 3;
        
        .swiper-card-list {
          margin: 70px 0;
        }
      }
      .img-list-card {
           flex-wrap: wrap;
          justify-content: space-between;
          transition-property: transform;
          row-gap: 20px;
         .img-list {
          width: 300px;
          height: 120px;
          line-height: 120px;
          text-align: center;
          background: #fff;
          border-radius: 8px;
          // margin-bottom: 20px;
          img {
            width: 224px;
            height: 64px;
          }
        }
      }
      
    }
     
</style>
