<template>
  <el-head class="fiex">
     <div class="margin-center flexs">
     <div class="link">
       <router-link :to="'/'" class="img-logo"></router-link>
        <el-menu
        :default-active="'/'"
        class="el-menu-demo "
        style="border:none"
        router
        mode="horizontal"
        text-color="#1f252e"
        active-text-color="#4e9e80">
        <el-menu-item index="/">首页</el-menu-item>
        <el-submenu index="/product">
          <template slot="title">产品介绍</template>
          <el-menu-item index="/product/advertisement">品牌广告</el-menu-item>
          <el-menu-item index="/product/SMS">智能短信</el-menu-item>
          <el-menu-item index="/product/advertisement-info">信息流广告</el-menu-item>
          <el-menu-item index="/product/fast-app">快应用</el-menu-item>
        </el-submenu>
        <el-submenu index="/news">
          <template slot="title">行内资讯</template>
          <el-menu-item index="/news" @click.native="links(item.url)" v-for="item in urls" :key="item.id">{{item.name}}</el-menu-item>
          
        </el-submenu>
        <!-- <el-menu-item index="/news" @click.native="links">行内资讯</el-menu-item> -->
        <el-menu-item index="/about">关于我们</el-menu-item>
        <el-menu-item index="/join">加入我们</el-menu-item>
        <el-menu-item index="/contact">联系我们</el-menu-item>
      </el-menu>
        <!-- <router-link :to="'/'" :class="{'active': activeUrl == '/'}">首页</router-link>
       <el-dropdown placement="bottom-start">
        <span class="el-dropdown-link">
          产品介绍
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>OPPO品牌广告</el-dropdown-item>
          <el-dropdown-item>OPPO智能短信</el-dropdown-item>
          <el-dropdown-item>O&V信息流广告</el-dropdown-item>
          <el-dropdown-item>快应用</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
       <router-link :class="{'active': activeUrl == item.url}" v-show="index != 2 && index!= 0" v-for="(item, index) in routerJson" :key="index"  :to="item.url" @click.native="edit(item.url)">{{item.name}}</router-link> -->
      </div>
     </div>
  </el-head>
</template>

<script>
import {routerJson} from '@/router/data'
export default {
  name: 'headsH',
  props: {
    msg: String,
  },
  data() {
    return {
       routerJson: [],
       activeUrl: '/',
       urls:[
        {id:0,name:'OPPO' ,url:"https://e.oppomobile.com/marketing/news/newslist.html"},
        { id:1,name:'VIVO' ,url:"https://dev.vivo.com.cn/?from=header"},
        { id:2,name:'HONER荣耀商推',url:"https://ads.hihonor.com/ "},
        { id:3,name:'小米营销',url:"http://e.mi.com/"},
        { id:4,name:'鲸鸿动能',url:"https://developer.huawei.com/consumer/cn/huawei-ads/"}
    ]
    }
  },
  mounted() {
    this.routerJson = routerJson;
    this.activeUrl = this.$route.path;
  },
  methods: {
    links(url) {
     
        window.open(url, "_blank")
      
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
.el-menu{
  li {
    display: block;
  }
}
.fiex {
  position: sticky;
  left: 0;
  right:0;
  background: #fff;
  box-shadow: 0 0 5px #eceaea;
  border: none;
  z-index: 1000;
  display: flex;
  height: 64px;
  justify-content: space-between;
}
.flexs {
  display: flex;
   justify-content: space-between;
   width: 80%;
   margin: 0 auto;
}
.el-dropdown-menu__item{
      display: block !important;
}
.link {
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  
  a, .el-dropdown-link{
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    font-size: 16px;
    font-weight: bold;
    height: 100%;
  }
  a.active{
    color: #4e9e80;
    border-bottom: 3px solid #4e9e80;
  }
  .img-logo {
    height: 51px;
    width: 86px;
    display: inline-block;
    background: url('../assets/image/43.png') no-repeat 0 0;
    background-size: contain;
    flex: 2;
    margin-left: 0;
 }
}
</style>
