import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
Vue.use(VueRouter)
const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ContactOur.vue')
  },
  {
    path: '/news',
    name: 'news',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsView.vue')
  },
  {
    path: '/newsDetils',
    name: 'newsDetils',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/NewsDetils.vue')
  },
  {
    path:"/product/advertisement",
    name:"advertisement",
    component: () => import(/* webpackChunkName: "about" */ '../views/product/AdvertisementView.vue')
  },
  {
    path:"/product/SMS",
    name:"SMS",
    component: () => import(/* webpackChunkName: "about" */ '../views/product/SmsView.vue')
  },
  {
    path:"/product/advertisement-info",
    name:"advertisement-info",
    component: () => import(/* webpackChunkName: "about" */ '../views/product/AdvertisementInfo.vue')
  },
  {
    path:"/product/fast-app",
    name:"fast-app",
    component: () => import(/* webpackChunkName: "about" */ '../views/product/FastApp.vue')
  },
  {
    path:"/join",
    name:"join",
    component: () => import(/* webpackChunkName: "about" */ '../views/JoinUs.vue')
  }
 
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
