<template>
  <div id="app">
     <el-container class="flex-container">
      <Heads></Heads>
      <el-main style="padding: 0"><router-view/></el-main>
      <Foot></Foot>
    </el-container>
  </div>
</template>
<script>
import Heads from "@/components/head.vue"
import Foot from "@/components/foot.vue"
export default {
   components: {
     Heads,
     Foot
   },
   data() {
     return {
       
     }
   },
   mounted() {
   
    //  routerList
   }
}
</script>
<style lang="scss">
@import url('./assets/css/common.css');
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  .flex-container {
     flex-direction: column !important;
  }
  min-height: 100%;
  overflow-y: auto;
}
body{
  margin: 0;
}
</style>
